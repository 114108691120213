import React, { Component } from 'react';
import { connect } from 'react-redux';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment from 'moment';

import { checkin } from '../../../../store/actions/userActions';
import './UserView.css';

class JumboHello extends Component {
  state = {
    checked: false,
  }

  render() {
    const { profile, writeupKeys, requestedProjects,
            needToConfirmProjects, confirmedProjects, isMobile, userRole } = this.props;
    
    // Check whether to display check-in button or not
    var checked = false;
    if (profile.checkins) {         // DO TESTING FOR DIFF DAYS, ESPECIALLY SUNDAY/MONDAY
      if (profile.checkins[moment().subtract(moment().day() - 1, 'days').format('M-D-YY')]) {
        checked = true;
      }
    }

      return (
        <>
          <Jumbotron style={{marginTop: 5}} className={isMobile ? "MobileJumbo" : "Jumbo"}>
            <Row>
              <Col lg={6} xs={12}>
                <h1>Hello, {profile.firstName} </h1>

                {!checked && userRole.lower === 'tech' && (
                  <p>
                    You are not checked-in for this week. Please visit the project sign-up page to check-in.
                  </p>
                )}

                {checked && userRole.lower === 'tech' && (
                  <p>
                    Next check-in will be available {moment().add(7 - moment().day() + 1, 'days').format('dddd M/D')}
                  </p>
                )}

                &nbsp;

                {((isMobile || userRole.lower !== 'tech') && !needToConfirmProjects.length && !confirmedProjects.length && !requestedProjects.length) && (
                  <p>Hit the sign up button below to add some projects!</p>
                )}

                {needToConfirmProjects.length > 0 && (
                  <p>
                    {`You have ${needToConfirmProjects.length === 1 ? 'a project' : 'multiple projects'} to confirm below!`}
                  </p>
                )}

                {confirmedProjects.length > 0 && (
                  <p>
                    {`You are signed up for ${confirmedProjects.length} project${confirmedProjects.length === 1 ? '' : 's'} this week`}
                  </p>
                )}

                {requestedProjects.length > 0 && (
                  <p>
                    {`You are awaiting ${
                      requestedProjects.length === 1 
                        ? `a response on ${requestedProjects.length} project` 
                        : `responses on ${requestedProjects.length} projects`
                    } this week`}
                  </p>
                )}

                <Button 
                  href="/projectsignup" 
                  style={{marginLeft: 0}} 
                  disabled={!!writeupKeys.length}
                >
                  Check-In | Sign Up for Projects
                </Button>

                {/* <Button 
                  href="/projectsignup" 
                  style={isMobile ? { marginBottom: 25, marginLeft: 0 } : { marginLeft: 0 }} 
                  block={isMobile} 
                  disabled={!!writeupKeys.length}
                >
                  Sign Up for Projects
                </Button> */}


                {userRole.lower === 'tech' && writeupKeys.length === 0 && (
                  <p style={{fontSize: 12}}><i>Viewing the available projects automatically checks you in</i></p>
                )}
                
              </Col>
              
              {writeupKeys.length > 0 && (
                <Col lg={6} xs={12}>
                  <h2> Notifications </h2>
                  <p>
                    {`You have`} <b>{`${writeupKeys.length}`}</b> {`writeup${writeupKeys.length > 1 ? 's' : ''} to view, please visit your profile at the top right corner`}
                  </p>
                </Col>
              )}

            </Row>
          </Jumbotron>
        </>
      )
    
  }



  /***** HELPER FUNCTIONS BEGIN *****/
  gatherNumberOfUnseenWriteups = (writeupsAsObject) => {
    var writeupKeys = [];
    Object.entries(writeupsAsObject).forEach(entry => {
      const writeupID = entry[0];
      const writeupData = entry[1];
  
      if (writeupData.status === "Not seen") {
        writeupKeys.push(writeupID);
      }
    })
    return writeupKeys;
  }
  /***** HELPER FUNCTIONS END *****/
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkin: (userID, weekOf) => dispatch(checkin(userID, weekOf))
  }
}

export default connect(null, mapDispatchToProps)(JumboHello);